import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider, QueryClient, useQuery } from 'react-query';
// import { RouterProvider } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { LicenseManager } from 'ag-grid-enterprise';
import { ConfigProvider, theme } from 'antd';
import 'antd/dist/reset.css';
import Root from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const GOOGLE_CLIENT_ID = '815491023422-70fvamf2ffft26mp1g78891fuieuft5l.apps.googleusercontent.com';
// const GOOGLE_CLIENT_ACCESS_SECRET = "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";
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-045422 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Calyx Global, Inc. )_is_granted_a_( Multiple Applications )_Developer_License_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 July 2024 )____[v2]_MTcyMTQzMDAwMDAwMA==e512e60ec7663b119e5d42b414dccfc7');

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={{
          token: {
            colorPrimary: '#1f7a1f',
            borderRadius: 2,
          },
          algorithm: []
        }}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </ConfigProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
