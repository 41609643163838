import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const LoginComponent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setUserDataAndNavigate } = useAuth();

  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    const refreshToken = searchParams.get('refresh_token');
    const role = searchParams.get('role');
    if (accessToken && refreshToken) {
      const user = {
        response: {
          access_token: accessToken,
          refresh_token: refreshToken,
          role: role,
        },
      };
      setUserDataAndNavigate(user);
    } else {
      navigate('/login');
    }
  }, [navigate, searchParams]);

  return <></>;
};

export default LoginComponent;
