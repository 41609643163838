import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, map, groupBy, find, isString, filter } from 'lodash';
import { Form, Input, Spin, Tooltip, AutoComplete, DatePicker, Select, Checkbox, Radio, InputNumber, Space, Button, Modal, message, Divider, Row, Col } from 'antd';
import { LoadingOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getProjectById, getProjectNames, updateProject, createProjectCreditingPeriod } from '../../../api/project';
import { getGHGMethodologies, getGHGStandards } from '../../../api/ghg';


import dayjs from 'dayjs';
import { Option } from 'antd/es/mentions';
import { getProjectTypes } from '../../../api/project_type';

const { RangePicker } = DatePicker;


interface ProjectDetailFormProps {
  form: any;
  updateParentDetails: any;
  dateType: any;
  setDateType: React.Dispatch<React.SetStateAction<any>>;
}

const ProjectDetailForm: React.FC<ProjectDetailFormProps> = ({ form, updateParentDetails, dateType, setDateType }) => {
  const { id, creditingPeriodId } = useParams<{ id, creditingPeriodId: string }>();
  const [project, setProject] = useState<any>({});
  const [ghgStandards, setGHGStandards] = useState([]);
  const [ghgMethodologies, setGHGMethodologies] = useState([]);
  const [projectTypes, setProjectTypes] = useState<{ group: string, types: string[] }[]>([]);
  const [creditingPeriod, setCreditingPeriod] = useState<any>({});
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [projectNames, setProjectNames] = useState<any[]>([]);
  const [dateTypeCP, setDateTypeCP] = useState<any>('date');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formcp] = Form.useForm();


  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProject() {
      setLoading(true);
      try {
        const [
          projectData,
          projects,
          ghgStandards,
          ghgMethodologies,
          projectTypes
        ] = await Promise.all([
          getProjectById(id, Number(creditingPeriodId)),
          getProjectNames(),
          getGHGStandards(),
          getGHGMethodologies(),
          getProjectTypes()
        ]);
        setProject(projectData.response);
        const projectEnrichedNames = projects.projects.filter(project => project.is_live).map(project => {
          return {
            id: project.id,
            name: `${project.ghg_standard.code}${project.project_id}: ${project.name}`,
          };
        });
        setProjectNames(projectEnrichedNames);
        setProjectTypes(
          map(groupBy(projectTypes.response, 'project_group.display_name'), (types, groupName) => ({
            group: groupName,
            types: types.map(type => type.display_name)
          }))
        );
        setSearchOptions(projects.projects.filter(project => project.is_live).map(project => 
            ({ value: `${project.ghg_standard.code}${project.project_id}: ${project.name}`, key: project.id })));
        setGHGStandards(ghgStandards.response.map(ghgStandard => ghgStandard.code));
        setGHGMethodologies(ghgMethodologies.response.map(ghgMethodology => ghgMethodology.display_name));
        const creditingPeriod = projectData.response.project_crediting_periods.find(creditingPeriod => creditingPeriod.crediting_period_id === Number(creditingPeriodId));
        updateParentDetails({
          projectNames: projectEnrichedNames,
          projectTypes: projectTypes.response,
          ghgStandards: ghgStandards.response,
          ghgMethodologies: ghgMethodologies.response,
          projectWorkflow: creditingPeriod.project_workflow,
          project: projectData.response,
        });;
        setLoading(false);
        setCreditingPeriod(creditingPeriod);
        if (creditingPeriod.date_type) {
          setDateType(creditingPeriod.date_type);
        }
        const projectDuration: any[] = [];
        if (projectData.response.start_year) {
          projectDuration.push(dayjs.utc(projectData.response.start_year));
        } else {
          projectDuration.push(undefined);
        }
        if (projectData.response.end_year) {
          projectDuration.push(dayjs.utc(projectData.response.end_year));
        } else {
          projectDuration.push(undefined);
        }
        const creditingPeriodDuration: any[] = [];
        if (creditingPeriod.start_date) {
          creditingPeriodDuration.push(dayjs.utc(creditingPeriod.start_date));
        } else {
          creditingPeriodDuration.push(undefined);
        }
        if (creditingPeriod.end_date) {
          creditingPeriodDuration.push(dayjs.utc(creditingPeriod.end_date));
        } else {
          creditingPeriodDuration.push(undefined);
        }
        form.setFieldsValue({
          projectName: projectData.response.name,
          about: projectData.response.about,
          projectId: projectData.response.project_id,
          ghgStandard: projectData.response.ghg_standard.code,
          ghgMethodology: projectData.response.ghg_methodologies.map(ghgMethodology => ghgMethodology.ghg_methodology.display_name)[0],
          projectType: projectData.response.project_types.map(projectType => projectType.project_type.display_name)[0],
          projectParent: !isEmpty(projectData.response.parent) ? `${projectData.response.parent.ghg_standard.code}${projectData.response.parent.project_id}: ${projectData.response.parent.name}` : '',
          yearRange: projectDuration,
          creditingPeriodDuration,
          creditingPeriodNarration: creditingPeriod.title,
        });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project:', error);
      }
    }

    fetchProject();
  }, [id, form]);

  const handleSearch = (value: string) => {
    const filteredOptions = projectNames.filter(project =>
      project.name.toLowerCase().includes(value.toLowerCase())
    ).map(projectdata => ({ value: projectdata.name, key: projectdata.id }));
    setSearchOptions(filteredOptions);
  };

  const handleDateTypeChange = (dateType) => {
    setDateType(dateType);
  };

  const handleDateTypeChangeCP = (dateTypeCP) => {
    setDateTypeCP(dateTypeCP);
  };

  const handleAddCreditingPeriod = () => {
    setOpen(true);
  };

  const handleModalOk = async () => {
    try {
      const formatDate = date => dayjs(date).format('YYYY-MM-DD');
      const formData = formcp.getFieldsValue();
      const response = await formcp.validateFields();
      try {
        setOpen(false);
        setLoading(true);
        const response = await createProjectCreditingPeriod({
          creditingPeriodId: creditingPeriod.id,
          creditingPeriodDateType: dateTypeCP,
          creditingPeriodStartDate: formatDate(formData.creditingPeriodDuration[0]),
          creditingPeriodEndDate: formatDate(formData.creditingPeriodDuration[1]),
          ...(formData.creditingPeriodNarration && {
            creditingPeriodNarration: formData.creditingPeriodNarration,
          }),
        });
        setLoading(false);
        message.success('Created the crediting period successfully');
        setLoading(true);
        navigate(`/project/edit/${response.response?.project_id}/cp/${response.response?.crediting_period_id}/step/0`, {
          replace: true,
        });
        await new Promise(resolve => setTimeout(resolve, 500));
        window.location.reload();
      } catch (err: any) {
        console.log(`Error creating project crediting period: ${err}`);
        setLoading(false);
        message.error({
          content: err.message || 'Unable to create crediting period',
          duration: 4,
          style: { textAlign: 'right' },
        });
      }
    } catch (err) {
      console.log('error', err);
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' },
      });
    }
  };

  const handleModalCancel = () => {
    setOpen(false);
  };

  return (
    <>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
        <Modal
          title="Add Crediting Period"
          open={open}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          width={800}
        >
          <Form
            form={formcp}
            layout="vertical">
            <Form.Item label="Crediting Period / Vintage Duration" initialValue={dateTypeCP} rules={[
              { required: true, message: 'Please input Crediting Period Date Type!' },
            ]}>
              <div className='flex gap-4'>
                <Select value={dateTypeCP} onChange={handleDateTypeChangeCP} style={{ width: '80px' }}>
                  <Option value="date">Date</Option>
                  <Option value="year">Year</Option>
                </Select>
                <Form.Item name="creditingPeriodDuration" rules={[
                  { required: true, message: 'Please input Crediting Period Duration!' },
                ]}>
                  <RangePicker picker={dateTypeCP === 'year' ? 'year' : 'date'} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label="Crediting Period Narration" name="creditingPeriodNarration">
              <Input placeholder="Crediting Period Narration"  />
            </Form.Item>
          </Form>
        </Modal>

        <div className='flex gap-2'>
          <Form.Item
            label="Registry"
            name="ghgStandard"
            rules={[{ required: true, message: 'Please select GHG Standard!' }]}
          >
            <Select>
              {ghgStandards.map((standard: string) => (
                <Option key={standard} value={standard}>{standard}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Project ID"
            name="projectId"
            rules={[{ required: true, message: 'Please input Project ID!' }]}
          >
            <Input type='number' />
          </Form.Item>
        </div>
        <Form.Item
          label={
            <span>
              Project Name&nbsp;
              <Tooltip title={(
                <ul className='mt-2'>
                  <li>Should be 4 or 5 words, around 50 characters long </li>
                  <li>Try to stay as true to the original project name as possible (e.g., delete extraneous words, but don’t change existing ones) </li>
                  <li>If changing original project name, be sure and include full project name in blurb Capitalize all words except conjunctions (and, the, in, etc.)</li>
                </ul>
              )}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="projectName"
          rules={[
            { required: true, message: 'Please input Project Name!' },
          ]}
        >
          <Input count={{
            show: true
          }}/>
        </Form.Item>
        <div className='flex w-full gap-2'>
          <Form.Item
            label="Methodology"
            name="ghgMethodology"
            className='w-96'
            rules={[{ required: true, message: 'Please select GHG Methodology!' }]}
          >
            <Select
              showSearch
              filterOption={(inputValue, option) =>
                option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
              }>
              {ghgMethodologies.map((methodology: string) => (
                <Option
                  key={methodology}
                  value={methodology}
                  style={{ fontWeight: form.getFieldValue('ghgMethodology') === methodology ? 'bold' : 'normal' }}
                >{methodology}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Project Type"
            name="projectType"
            className='w-72'
            rules={[{ required: true, message: 'Please select project type!' }]}
          >
            <Select
              showSearch
              filterOption={(inputValue, option) =>
                option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
              }>
              {projectTypes.map((group) => (
                <Select.OptGroup key={group.group} label={<span style={{ fontWeight: 'bold' }}>{group.group}</span>}>
                  {group.types.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="About"
          name="about"
          rules={[{ required: false, message: 'Please input about!' }]}
        >
          <Input.TextArea 
            rows={4}
          />
        </Form.Item>
        <Form.Item
          label="Project Parent"
          name="projectParent"
          rules={[{ required: false }]}
        >
          <AutoComplete
            options={searchOptions}
            placeholder="Type project name or GHG standard + project ID"
            onSearch={handleSearch}
          />
        </Form.Item>
        <Form.Item
          label="Project Duration"
          name="yearRange"
          rules={[{ required: false, message: 'Please select Project Duration!' }]}
        >
          <RangePicker />
        </Form.Item>
        <Divider orientation='left'>Crediting period/Vintage</Divider>
        <div className='flex gap-4'>
          <Form.Item label="Duration">
            <div className='flex gap-4'>
              <Select value={dateType} onChange={handleDateTypeChange} style={{ width: '120px' }}>
                <Option value="date">Date</Option>
                <Option value="year">Year</Option>
              </Select>
              <Form.Item name="creditingPeriodDuration">
                <RangePicker picker={dateType === 'year' ? 'year' : 'date'} />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label="Narration" name="creditingPeriodNarration" className='flex-1'>
            <Input placeholder="Narration" />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="default"
            icon={<PlusCircleOutlined />}
            onClick={handleAddCreditingPeriod}
          >
            Add Crediting Period
          </Button>
        </Form.Item>
      </>
  );
};

export default ProjectDetailForm;
