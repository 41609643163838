import { Alert, Spin, theme, Layout, Breadcrumb, Button, Select, Card, Input, Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { uniqBy, debounce } from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { getSharedContent, putSharedContent, deleteSharedContent } from '../api';
import useAuth from '../hooks/useAuth';
import CalyxReadonlyCKEditor from '../components/CKEditor/readonly';
import CKEditorClassic from '../components/CKEditor/classic';
import CalyxCKEditorClassic from '../components/CKEditor/classic';

const { Header } = Layout;


const SharedContentList: React.FC<{ data: any }> = ({ data }) => {
    const { auth } = useAuth();
    const [selectedProjectType, setSelectedProjectType] = React.useState<string | undefined>(undefined);
    const [selectedProjectTypeGroup, setSelectedProjectTypeGroup] = React.useState<string | undefined>(undefined);

    const client = useQueryClient();

    const {
        mutate: updateSharedContent,
        isLoading,
        error
    } = useMutation(putSharedContent, {
        onSuccess: (data) => {
            client.invalidateQueries('shared_content');
        }
    });

    const { mutate: removeSharedContent } = useMutation(deleteSharedContent, {
        onSuccess: () => {
            client.invalidateQueries('shared_content');
        }
    });

    const onDebouncedSharedContentUpdate = (item, key) => debounce(onSharedContentUpdate(item, key), 500);
    const onSharedContentUpdate = (item, key) => (value, delta, source, editor) => {
        const obj = {};
        const objKey = key || 'title';
        obj[objKey] = value;
        updateSharedContent({
            sharedContentID: item.id,
            data: obj
        });
    };

    const onDebouncedSharedContentTitleUpdate = (item, key) => debounce(onSharedContentTitleUpdate(item, key), 500);
    const onSharedContentTitleUpdate = (item, key) => (evt) => {
        const obj = {};
        const objKey = key || 'title';
        obj[objKey] = evt.target.value;
        updateSharedContent({
            sharedContentID: item.id,
            data: obj
        });
    };

    const projectTypes = useMemo(() => {
        return uniqBy(data, 'project_type').map(d => d.project_type).filter(f => f);
    }, [data]);

    const projectTypeGroups = useMemo(() => {
        return uniqBy(data, 'project_type_group').map(d => d.project_type_group).filter(f => f);
    }, [data]);

    const projectTypeGroup = useMemo(() => {
        return uniqBy(data, 'project_type_group').map(d => d.project_type).filter(f => f);
    }, [data]);

    const onProjectTypeChange = useCallback((value) => {
        setSelectedProjectType(value);
    }, [1]);

    const onProjectTypeGroupChange = useCallback((value) => {
        setSelectedProjectTypeGroup(value);
    }, [1]);

    let filteredData = data;
    if (selectedProjectType || selectedProjectTypeGroup) {
        filteredData = data.filter(d => {
            if (selectedProjectType) {
                if (selectedProjectTypeGroup) {
                    return d.project_type === selectedProjectType && d.project_type_group === selectedProjectTypeGroup;
                } else {
                    return d.project_type === selectedProjectType;
                }
            } else if (selectedProjectTypeGroup) {
                return d.project_type_group === selectedProjectTypeGroup;
            }

            return true;
        });
    }

    const showDeleteConfirm = (item) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this shared content?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                removeSharedContent({ sharedContentID: item.id });
            }
        });
    };


    return (
        <div className='p-4'>
            <div className='flex items-center gap-3'>
                <Select
                    className='w-56'
                    placeholder='Select project type group'
                    options={projectTypeGroups.map(p => ({ label: p, value: p }))}
                    allowClear
                    value={selectedProjectTypeGroup}
                    onChange={onProjectTypeGroupChange}
                />
                <Select
                    className='w-56'
                    placeholder='Select project type'
                    options={projectTypes.map(p => ({ label: p, value: p }))}
                    allowClear
                    value={selectedProjectType}
                    onChange={onProjectTypeChange}
                />
            </div>
            <div className='h-full w-full flex flex-col gap-5 mt-5'>
                {filteredData.map((d, i) => {
                    return (
                        <Card key={d.id}>
                            <div className='mb-2 flex justify-between items-center'>
                                <Input
                                    defaultValue={d.name}
                                    onChange={onDebouncedSharedContentTitleUpdate(d, 'name')}
                                />
                                <Button className='hidden' type='primary' danger onClick={() => showDeleteConfirm(d)}>
                                    Delete
                                </Button>
                            </div>
                            <CalyxCKEditorClassic
                                data={d.content}
                                readOnly={['team_lead', 'super_admin'].indexOf(auth?.user?.role) === -1}
                                onDataChange={onDebouncedSharedContentUpdate(d, 'content')}
                            />
                            {/* <ReactQuill
                                theme='snow'
                                defaultValue={d.content}
                                onChange={onDebouncedSharedContentUpdate(d, 'content')}
                            /> */}
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};


const SharedContent: React.FC = () => {
    const {
        token: { colorBgContainer }
    } = theme.useToken();

    const { logout } = useAuth();

    const { isLoading, isError, data } = useQuery('shared_content', getSharedContent);

    if (isLoading) {
        return (
            <Spin tip="Loading" size="large">
                <div className="content" />
            </Spin>
        );
    } else if (isError) {
        return (
            <Alert
                message="Something went wrong"
                description="Something went wrong during loading the shared content. Please wait for sometime, and refresh the page."
                type="error"
            />
        );
    }

    const sharedContent = data?.data?.data;

    return (
        <>
            <Header className='p-4 flex items-center justify-between' style={{ background: colorBgContainer }}>
                <div className='flex items-center gap-2'>
                    <Breadcrumb
                        className='mx-4'
                        items={[{
                            title: <Link to='/projects'>All projects</Link>
                        }, {
                            title: 'Shared Content'
                        }]}
                    />
                </div>
                {/* <Button type="primary" danger onClick={async () => {
                    logout();
                }}>
                    Logout
                </Button> */}
            </Header>
            <SharedContentList data={sharedContent} />
        </>
    );
};

export default SharedContent;
