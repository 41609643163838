import { Button, Card, Checkbox, Dropdown, Input, InputNumber, Popconfirm, Select, Switch } from 'antd';
import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import Map, { NavigationControl } from 'react-map-gl';
import { DownOutlined, DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { createRatingBlurbSection, deleteRatingBlurbSection } from '../../api';
import ContentEditor from '../ContentEditor';
import TableEditor from '../TableEditor';
import TimelineTableEditor from '../TimelineTableEditor';
import ChartCapture from '../ChartCapture';
import MapViewer from '../MapViewer';
import ImageUploader from '../ImageUploader';
import TextArea from 'antd/es/input/TextArea';
import { isProjectLive } from '../../utils';


enum TitleType {
    PAGE_TITLE = 'Page Title',
    SECTION_TITLE = 'Section Title',
    PARAGRAPH_TITLE = 'Paragraph Title',
    PARAGRAPH_SUBTITLE = 'Paragraph Subtitle',
}

type RatingBlurbSectionProps = {
    blurbSection: any;
    project: any;
    entityId: string;
    queryKey: string;
    onTitleChange: any;
    onRemoveSection: any;
    onToggleTitle: any;
    onSaveContent: any;
    tableIndex: string;
};

const RatingBlurbSection: React.FC<RatingBlurbSectionProps> = ({
    blurbSection,
    project,
    tableIndex,
    entityId,
    queryKey,
    onTitleChange,
    onToggleTitle,
    onRemoveSection,
    onSaveContent
}) => {
    const { type: sectionType } = blurbSection;

    let item = blurbSection.rating_blurb_section_paragraph;
    if (!item) {
        item = blurbSection.rating_blurb_section_table;
    }
    if (!item) {
        item = blurbSection.rating_blurb_section_chart;
    }
    if (!item) {
        item = blurbSection.rating_blurb_section_title;
    }
    if (!item) {
        item = blurbSection.rating_blurb_section_map;
    }
    if (!item) {
        item = blurbSection.rating_blurb_section_html;
    }
    if (!item) {
        item = blurbSection.rating_blurb_section_image;
    }
    if (!item) return null;

    let isNotEditable = item?.ckeditor_content_doc?.shared_content_paragraph;
    if (item?.is_editable === false) {
        isNotEditable = true;
    }


    const onDebouncedRatingBlurbSectionTitleChange = (item, sectionType, key) => debounce(onRatingSectionTitleChange(item, sectionType, key), 800);
    const onRatingSectionTitleChange = (item, sectionType, key) => (evt) => {
        const obj = {};
        const objKey = key || 'title';
        obj[objKey] = evt.target.value;
        onSaveContent(item, sectionType)(obj);
    };

    const onItemHiddenCBChange = (evt) => {
        onSaveContent(item, sectionType)({
            is_hidden: evt.target.checked
        });
    }

    return (
        <div className='mt-4 border border-solid border-neutral-200 rounded-md'>
            <div className="flex px-2 gap-4 items-center mb-4 border-0 border-b border-solid border-neutral-200">
                <div className="flex-1 capitalize">
                    {(sectionType === 'table' ? 'cards_section' : sectionType).split('_').join(' ')} - {tableIndex}
                </div>
                {!isProjectLive(project) && blurbSection.section_type !== 'read_more_blurb_section_timeline_caption' &&
                    blurbSection.section_type !== 'read_more_blurb_over_crediting_chart_caption' &&
                    !blurbSection.section_type.includes('chart_caption') && sectionType !== 'chart' &&
                    (
                        <Popconfirm
                            title={`"Remove Section - ${sectionType.split('_').join(' ')} - ${tableIndex}`}
                            description="Are you sure to remove this section"
                            icon={(
                                <QuestionCircleOutlined style={{ color: 'red' }} />
                            )}
                            onConfirm={onRemoveSection(blurbSection)}
                            okText="Yes, Delete the section"
                            cancelText="No, do not delete"
                        >
                            <Button type="link" danger icon={<DeleteFilled />}>
                                Remove
                            </Button>
                        </Popconfirm>
                    )
                }
                {sectionType === 'chart' &&
                    (
                        <Checkbox
                            defaultChecked={item.is_hidden}
                            onChange={onItemHiddenCBChange}>
                            Hide
                        </Checkbox>
                    )
                }
            </div>
            <div className="flex px-2 gap-4 items-center mb-4 flex-1">
                {sectionType === 'paragraph' && (
                    <div className="flex-1">
                        {item.title_enabled ? (
                            <div className="flex gap-4 items-center mb-2">
                                <Input
                                    disabled={isProjectLive(project)}
                                    showCount
                                    allowClear
                                    maxLength={450}
                                    defaultValue={item.title}
                                    placeholder='Title'
                                    readOnly={isNotEditable}
                                    onChange={onTitleChange(item)}
                                />
                                {isNotEditable ? null :
                                    <Checkbox
                                        disabled={isProjectLive(project)}
                                        className='w-40'
                                        defaultChecked={item.title_visible}
                                        onChange={onToggleTitle(item)}
                                    >Show Title</Checkbox>}
                            </div>
                        ) : null}
                        <ContentEditor
                            project={project}
                            saveData={onSaveContent(item, sectionType)}
                            sectionType={sectionType}
                            entityId={entityId}
                            queryKey={queryKey}
                            id={item.id}
                            data={item.ckeditor_content_doc}
                        />
                    </div>
                )}
                {sectionType === 'table' && (
                    <div className="flex-1">
                        <TableEditor
                            project={project}
                            id={item.id}
                            queryKey={queryKey}
                            section={blurbSection}
                            item={item}
                            entityId={entityId}
                            saveData={onSaveContent(item, sectionType)}
                        />
                    </div>
                )}
                {sectionType === 'chart' && (
                    <div className='flex flex-col flex-1'>
                        <Input type='text'
                            disabled={isProjectLive(project)}
                            defaultValue={item.title}
                            onChange={onDebouncedRatingBlurbSectionTitleChange(item, sectionType, 'title')}
                        />
                        <div className="flex-1">
                            <ChartCapture
                                id={item.id}
                                queryKey={queryKey}
                                project={project}
                                section={blurbSection}
                                item={item}
                                entityId={entityId}
                                saveData={onSaveContent(item, sectionType)}
                            />
                        </div>
                    </div>
                )
                }
                {sectionType === 'timeline_table' && (
                    <div className="flex-1">
                        <TimelineTableEditor
                            project={project}
                            id={item.id}
                            queryKey={queryKey}
                            section={blurbSection}
                            item={item}
                            entityId={entityId}
                            saveData={onSaveContent(item, sectionType)}
                        />
                    </div>
                )}
                {
                    sectionType === 'title' && (
                        <div className='flex-1 flex'>
                            <Select className='w-44' defaultValue={item.title_type} onChange={value => {
                                onSaveContent(item, sectionType)({
                                    title_type: value
                                });
                            }}>
                                {Object.keys(TitleType).map((key) => (
                                    <Select.Option key={key} value={key} className='capitalize'>
                                        {TitleType[key]}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Input type='text'
                                disabled={isProjectLive(project)}
                                defaultValue={item.title}
                                onChange={onDebouncedRatingBlurbSectionTitleChange(item, sectionType, 'title')}
                            />
                        </div>
                    )
                }
                {
                    sectionType === 'html' && (
                        <>
                            <div className='flex-1'>
                                <TextArea
                                    placeholder="Please enter HTML content"
                                    defaultValue={item.html}
                                    onChange={onDebouncedRatingBlurbSectionTitleChange(item, sectionType, 'html')}
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item.html }} />
                        </>
                    )
                }
                {
                    sectionType === 'map' && (
                        <div className='flex-1'>
                            <div className='flex'>
                                {/* <Select className='w-44' defaultValue={item.title_type} onChange={value => {
                                    onSaveContent(item, sectionType)({
                                        title_type: value
                                    });
                                }}>
                                    {Object.keys(TitleType).map((key) => (
                                        <Select.Option key={key} value={key} className='capitalize'>
                                            {TitleType[key]}
                                        </Select.Option>
                                    ))}
                                </Select> */}
                                <Input type='text'
                                    disabled={isProjectLive(project)}
                                    placeholder='Maptiler Link (https://api.maptiler.com/maps/{mapId}/style.json?key={apiKey})'
                                    defaultValue={item.maptiler_link}
                                    onChange={onDebouncedRatingBlurbSectionTitleChange(item, sectionType, 'maptiler_link')}
                                />
                            </div>
                            <MapViewer
                                disabled={isProjectLive(project)}
                                maptilerLink={item.maptiler_link}
                                maptilerViewState={item.maptiler_viewstate}
                                maptilerLayers={item.maptiler_layers}
                                saveData={onSaveContent(item, sectionType)}
                            />
                        </div>
                    )
                }
                {
                    sectionType === 'image' && (
                        <ImageUploader
                            project={project}
                            id={item.id}
                            queryKey={queryKey}
                            section={blurbSection}
                            item={item}
                            entityId={entityId}
                            saveData={onSaveContent(item, sectionType)}
                        />
                    )
                }
            </div>
            {blurbSection.children?.sort((a, b) => a.order - b.order).map((child_section, childIndex) => {
                return (
                    <div className='m-3' key={child_section.id}>
                        <RatingBlurbSection
                            project={project}
                            tableIndex={`${tableIndex}-${childIndex}`}
                            blurbSection={child_section}
                            onTitleChange={onTitleChange}
                            onRemoveSection={onRemoveSection}
                            onToggleTitle={onToggleTitle}
                            onSaveContent={onSaveContent}
                            entityId={entityId}
                            queryKey={queryKey}
                        />
                    </div>
                );
            })}
        </div>
    );
};



type ReadMoreBlurbEditorProps = {
    field: any;
    project: any;
    entityId: string;
    queryKey: string;
    onSaveContent: any;
    onTitleChange: any;
    onToggleTitle: any;
};

const ReadMoreBlurbEditor: React.FC<ReadMoreBlurbEditorProps> = ({ field, project, entityId, queryKey, onSaveContent, onTitleChange, onToggleTitle }) => {
    const client = useQueryClient();
    const {
        mutate: addRatingBlurbSection,
        isLoading: isAdding,
        error: addError
    } = useMutation(createRatingBlurbSection, {
        onSuccess: (data) => {
            client.invalidateQueries({
                queryKey: [queryKey]
            });
        }
    });

    const {
        mutate: removeRatingBlurbSection,
        isLoading: isRemoving,
        error: removeError
    } = useMutation(deleteRatingBlurbSection, {
        onSuccess: (data) => {
            client.invalidateQueries({
                queryKey: [queryKey]
            });
        }
    });

    const onRemoveSection = (rating_blurb_section) => () => {
        removeRatingBlurbSection(rating_blurb_section.id);
    };

    const onAddSection = (order) => (evt) => {
        const sectionType = evt.key ? evt.key : 'paragraph';
        const blurbSectionData = {
            order: sectionType === 'timeline_table' ? order + 100 : order + 1,
            type: sectionType,
            rating_blurb_id: field.id,
            section_type: `read_more_blurb_section_${sectionType}`,
        } as any;

        if (sectionType === 'paragraph') {
            blurbSectionData.rating_blurb_section_paragraph = {
                create: {
                    title: '',
                    title_enabled: true,
                    title_visible: true
                }
            };
        } else if (sectionType === 'chart') {
            blurbSectionData.rating_blurb_section_chart = {
                create: {
                    title: 'Chart',
                    title_visible: false,
                }
            }
            blurbSectionData.children = {
                create: [{
                    order: order + 2,
                    type: 'paragraph',
                    rating_blurb_id: field.id,
                    section_type: `read_more_blurb_section_chart_caption`,
                    rating_blurb_section_paragraph: {
                        create: {
                            title: 'Chart Caption',
                            title_enabled: true,
                            title_visible: false
                        }
                    }
                }]
            };
        } else if (sectionType === 'table') {
            blurbSectionData.rating_blurb_section_table = {
                create: {
                    title: 'Table',
                    title_enabled: false,
                    title_visible: false,
                    rating_table_attributes: {
                        create: [{
                            name: 'type',
                            value: 'Type',
                            rating_table_data: {
                                create: []
                            }
                        }, {
                            name: 'status',
                            value: 'Status',
                            rating_table_data: {
                                create: []
                            }
                        }, {
                            name: 'description',
                            value: 'Description',
                            rating_table_data: {
                                create: []
                            }
                        }]
                    }
                }
            }
        } else if (sectionType === 'timeline_table') {
            blurbSectionData.children = {
                create: [{
                    order: order + 102,
                    type: 'paragraph',
                    rating_blurb_id: field.id,
                    section_type: `read_more_blurb_section_timeline_caption`,
                    rating_blurb_section_paragraph: {
                        create: {
                            title: 'Timeline Caption',
                            title_enabled: true,
                            title_visible: false
                        }
                    }
                }]
            };
            blurbSectionData.rating_blurb_section_table = {
                create: {
                    title: 'Timeline Table',
                    title_enabled: false,
                    title_visible: false,
                    rating_table_attributes: {
                        create: [{
                            name: 'timeline_date',
                            value: 'Timeline Date',
                            rating_table_data: {
                                create: []
                            }
                        }, {
                            name: 'description',
                            value: 'Description',
                            rating_table_data: {
                                create: []
                            }
                        }]
                    }
                }
            }
        } else if (sectionType === 'title') {
            blurbSectionData.rating_blurb_section_title = {
                create: {
                    title: '',
                    title_enabled: true,
                    title_visible: true
                }
            };
        } else if (sectionType === 'html') {
            blurbSectionData.rating_blurb_section_html = {
                create: {
                    html: '',
                }
            };
        } else if (sectionType === 'map') {
            blurbSectionData.children = {
                create: [{
                    order: order + 2,
                    type: 'paragraph',
                    rating_blurb_id: field.id,
                    section_type: `read_more_blurb_section_map_caption`,
                    rating_blurb_section_paragraph: {
                        create: {
                            title: 'Map Caption',
                            title_enabled: true,
                            title_visible: false
                        }
                    }
                }]
            };
            blurbSectionData.rating_blurb_section_map = {
                create: {
                    maptiler_link: '',
                    title_enabled: false,
                    title_visible: false
                }
            };
        } else if (sectionType === 'image') {
            blurbSectionData.children = {
                create: [{
                    order: order + 2,
                    type: 'paragraph',
                    rating_blurb_id: field.id,
                    section_type: `read_more_blurb_section_image_caption`,
                    rating_blurb_section_paragraph: {
                        create: {
                            title: 'Image Caption',
                            title_enabled: true,
                            title_visible: false
                        }
                    }
                }]
            };
            blurbSectionData.rating_blurb_section_image = {
                create: {
                    image_url: '',
                    alttext: '',
                }
            };
        }

        addRatingBlurbSection({
            data: blurbSectionData
        });
    };

    return (
        <>
            <div className='my-2 pt-4 border-t-gray-400 border-t border-solid border-0 -mx-6 px-6'>
                <div className={`flex gap-4 items-center mb-4 ${field.title_visible ? '' : 'hidden'}`}>
                    <div className="text-md font-bold uppercase">{field.title}</div>
                </div>
                <Dropdown.Button
                    disabled={isProjectLive(project)}
                    icon={<DownOutlined />}
                    menu={{
                        items: [
                            { key: 'title', label: 'Add Title' },
                            { key: 'timeline_table', label: 'Add Timeline' },
                            { key: 'map', label: 'Add Map' },
                            { key: 'html', label: 'Add HTML' },
                            { key: 'image', label: 'Add Image' },
                            { key: 'table', label: 'Add Cards Section' }
                        ],
                        onClick: onAddSection(0)
                    }}
                    onClick={onAddSection(0)}
                >
                    Add Paragraph
                </Dropdown.Button>
                {field.rating_blurb_sections.sort((a, b) => a.order - b.order).map((rating_blurb_section, index) => {
                    return (
                        <div key={rating_blurb_section.id}>
                            <RatingBlurbSection
                                project={project}
                                tableIndex={`${index}`}
                                blurbSection={rating_blurb_section}
                                onTitleChange={onTitleChange}
                                onRemoveSection={onRemoveSection}
                                onToggleTitle={onToggleTitle}
                                onSaveContent={onSaveContent}
                                entityId={entityId}
                                queryKey={queryKey}
                            />
                            <Dropdown.Button
                                className='mt-2'
                                disabled={isProjectLive(project)}
                                icon={<DownOutlined />}
                                menu={{
                                    items: [
                                        { key: 'title', label: 'Add Title' },
                                        { key: 'timeline_table', label: 'Add Timeline' },
                                        { key: 'map', label: 'Add Map' },
                                        { key: 'html', label: 'Add HTML' },
                                        { key: 'image', label: 'Add Image' },
                                        { key: 'table', label: 'Add Cards Section' }
                                    ],
                                    onClick: onAddSection(rating_blurb_section.order)
                                }}
                                onClick={onAddSection(rating_blurb_section.order)}
                            >
                                Add Paragraph
                            </Dropdown.Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ReadMoreBlurbEditor;
