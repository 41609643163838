import React, { useState } from 'react';
import type { DatePickerProps, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import { DatePicker, Select, Space } from 'antd';

const { Option } = Select;

type PickerType = 'date' | 'month' | 'year';

const PickerWithType = ({
    type,
    value,
    rangeType,
    onChange,
    disabled
}: {
    type: PickerType;
    rangeType: string;
    value: any;
    onChange: DatePickerProps['onChange'] | any;
    disabled: boolean;
}) => {
    if (rangeType === 'period') {
        return <DatePicker.RangePicker disabled={disabled} picker={type} onChange={onChange} value={value} />;
    }

    return <DatePicker disabled={disabled} picker={type} onChange={onChange} value={value} />;
};

type TimelineDatePickerProps = {
    defaultValue?: any;
    onChange: any;
    projectLive: boolean;
};

const TimelineDatePicker: React.FC<TimelineDatePickerProps> = ({ defaultValue, onChange, projectLive }) => {
    const defaultValueSplit = defaultValue?.split(':|:');

    const [type, setType] = useState<PickerType>(defaultValueSplit[0] || 'year');
    const [rangeType, setRangeType] = useState<any>(defaultValueSplit[1] || 'single');

    let dateDefaultValue;
    if (rangeType === 'single') {
        dateDefaultValue = defaultValueSplit[2] ? dayjs(defaultValueSplit[2]) : '';
    } else if (rangeType === 'period') {
        dateDefaultValue = defaultValueSplit[2] ? [dayjs(defaultValueSplit[2].split(',')[0]), dayjs(defaultValueSplit[2].split(',')[1])] : '';
    }
    const [dateValue, setDateValue] = useState<any>(dateDefaultValue);

    const onRangeTypeChange = (value) => {
        setRangeType(value);
        onChange(`${type}:|:${value}:|:${dateValue}`);
    };
    const onTypeChange = (value) => {
        setType(value);
        onChange(`${value}:|:${rangeType}:|:${dateValue}`);
    };
    const onPickerValueChange = (value, dateString) => {
        setDateValue(value);
        onChange(`${type}:|:${rangeType}:|:${dateString}`);
    };

    if (rangeType === 'period') {
        // debugger;
        console.log(dateValue);
    }
    return (
        <Space>
            <Select disabled={projectLive} value={rangeType} onChange={onRangeTypeChange}>
                <Option value="single">Single</Option>
                <Option value="period">Period</Option>
            </Select>
            <Select disabled={projectLive} value={type} onChange={onTypeChange}>
                <Option value="date">Date</Option>
                <Option value="month">Month</Option>
                <Option value="year">Year</Option>
            </Select>
            <PickerWithType disabled={projectLive} type={type} rangeType={rangeType} value={dateValue} onChange={onPickerValueChange} />
        </Space>
    );
};

export default TimelineDatePicker;