import React, { useState, useEffect } from 'react';
import { map, groupBy, find, isString, isEmpty, isUndefined, includes } from 'lodash';
import { Form, Spin, Select, Checkbox, Radio, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getProjectById, updateProject } from '../../../api/project';
import { getLocations } from '../../../api/location';
import { getProponents } from '../../../api/proponent';

import { Option } from 'antd/es/mentions';
import dayjs from 'dayjs';
import SelectProponent from '../dropdown/proponent';
import TextArea from 'antd/es/input/TextArea';

interface ProjectAttributesFormProps {
  form: any;
}

const ProjectAttributesForm: React.FC<ProjectAttributesFormProps> = ({ form }) => {
  const { id, creditingPeriodId } = useParams<{ id, creditingPeriodId: string }>();
  const [locationType, setLocationType] = useState<string>();
  const [proponents, setProponents] = useState([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchProject() {
      setLoading(true);
      try {
        const projectData = await getProjectById(id, Number(creditingPeriodId));
        const creditingPeriod = projectData.response.project_crediting_periods.find(creditingPeriod => creditingPeriod.crediting_period_id === Number(creditingPeriodId));
        const locationData = await getLocations();
        const proponentsData = await getProponents();
        setProponents(proponentsData.response.map(proponent => ({
          id: proponent.id,
          display_name: proponent.display_name,
          name: proponent.name,
        })));
        setLocations(locationData.response);
        const stateExist = projectData.response.locations.find(location => location.location.type === 'state');
        setLocationType(stateExist ? 'state' : 'country');
        setLoading(false);
        form.setFieldsValue({
          nature: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'nature'),
          removal: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'removal'),
          removal_and_avoidance: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'removal_and_avoidance'),
          ccp: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'ccp'),
          rightsizing: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'rightsizing'),
          corsia_pilot_phase: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'corsia_pilot_phase'),
          corsia_first_phase_pending: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'corsia_first_phase_pending'),
          corsia_first_phase_approved: find(creditingPeriod.project_attributes, projectAttribute => projectAttribute.attribute.name === 'corsia_first_phase_approved'),
          ...(stateExist && {
            projectState: projectData.response.locations.filter(location => location.location.type === 'state').map(location => location.location.id),
          }),
          ...(!stateExist && {
            projectCountry: projectData.response.locations.map(location => location.location.id)[0],
          }),
          proponent: projectData.response.proponents.map(proponent => proponent.proponent.id),
          locationType: stateExist ? 'state' : 'country',
          onWatch: projectData.response.onwatch === 1 ? true : false,
          onWatchReason: projectData.response.onwatch_reason,
        });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project:', error);
      }
    }

    fetchProject();
  }, [id, form]);

  const handleLocationTypeChange = (e) => {
    setLocationType(e.target.value);
  };

  const getLocationCountries = () => {
    return locations
      .filter((location) => location.type === 'country')
      .map((country) => (
        <Option key={country.id} value={country.id}>
          {country.display_name}
        </Option>
      ));
  }

  const getLocationStates = (): any => {
    return map(groupBy(locations.filter((location) => location.type === 'state'), 'parent.display_name'), (types, groupName) => ({
      group: groupName || 'no-parent',
      types: types.map(type => ({ id: type.id, display_name: type.display_name })),
    }));
  }

  const normalizeString = (str) => {
    return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.toLowerCase();
  };


  return (
    <>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <Form.Item
        label="Proponent"
        name="proponent"
        rules={[{ required: false, message: 'Please select Proponent!' }]}
      >
        <SelectProponent initialItems={proponents} form={form} />
      </Form.Item>
      <div className='flex gap-4'>
        <Form.Item label="Location" name="locationType">
          <Radio.Group value={locationType} onChange={handleLocationTypeChange}>
            <Radio.Button value="country">Country</Radio.Button>
            <Radio.Button value="state">State</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {locationType === 'country' && (
          <Form.Item label=" " name="projectCountry" className='flex-1'>
            <Select placeholder="Select Country" showSearch
              filterOption={(inputValue, option) => {
                const normalizedInput = normalizeString(inputValue);
                const normalizedOption = normalizeString(option?.children);
                return normalizedOption?.includes(normalizedInput);
              }}>
              {getLocationCountries()}
            </Select>
          </Form.Item>
        )}
        {locationType === 'state' && (
          <Form.Item label=" " name="projectState" className='flex-1'>
            <Select mode="multiple" placeholder="Select States" showSearch
              filterOption={(inputValue, option) => {
                const normalizedInput = normalizeString(inputValue);
                const normalizedOption = normalizeString(option?.children);
                return normalizedOption?.includes(normalizedInput);
              }}>
              {getLocationStates().map((group) => (
                <Select.OptGroup key={group.group} label={<span style={{ fontWeight: 'bold' }}>{group.group}</span>}>
                  {group.types.map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.display_name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        )}
      </div>
      <Form.Item
        label="Attributes"
        className='mb-0'
        rules={[{ required: false, message: 'Please select project attributes!' }]}
      >
        <div className='flex gap-5'>
          <Form.Item
            name={['nature', 'attribute_value']}
            valuePropName='checked'
          >
            <Checkbox>{form.getFieldValue(['nature', 'attribute', 'display_name']) || 'Nature'}</Checkbox>
          </Form.Item>

          <Form.Item
            name={['removal', 'attribute_value']}
            valuePropName='checked'
          >
            <Checkbox>{form.getFieldValue(['removal', 'attribute', 'display_name']) || 'Removals'}</Checkbox>
          </Form.Item>

          <Form.Item
            name={['removal_and_avoidance', 'attribute_value']}
            valuePropName='checked'
            >
            <Checkbox>{form.getFieldValue(['removal_and_avoidance', 'attribute', 'display_name']) || 'Removals + Avoidance'}</Checkbox>
          </Form.Item>

          <Form.Item
            name={['ccp', 'attribute_value']}
            valuePropName='checked'
            >
            <Checkbox>{form.getFieldValue(['ccp', 'attribute', 'display_name']) || 'CCP Eligible'}</Checkbox>
          </Form.Item>
          <Form.Item
            name={['rightsizing', 'attribute_value']}
            valuePropName='checked'
          >
            <Checkbox disabled={true}>{form.getFieldValue(['rightsizing', 'attribute', 'display_name']) || 'Rightsizing'}</Checkbox>
          </Form.Item>
        </div>
        <div className="flex gap-10">
          <Form.Item
            name={['corsia_pilot_phase', 'attribute_value']}
            valuePropName='checked'
            style={{ width: '20%' }}
          >
            <Checkbox>{form.getFieldValue(['corsia_pilot_phase', 'attribute', 'display_name']) || 'Corsia Pilot Phase'}</Checkbox>
          </Form.Item>
          <Form.Item
            name={['corsia_pilot_phase', 'attribute_extra_content']}
            label="Project Specific Content"
            style={{ width: '80%' }}
          >
            <TextArea />
          </Form.Item>
        </div>
        <div className="flex gap-10">
          <Form.Item
            name={['corsia_first_phase_pending', 'attribute_value']}
            valuePropName='checked'
            style={{ width: '20%' }}
            >
            <Checkbox>{form.getFieldValue(['corsia_first_phase_pending', 'attribute', 'display_name']) || 'Corsia First Phase Pending'}</Checkbox>
          </Form.Item>
          <Form.Item
            name={['corsia_first_phase_pending', 'attribute_extra_content']}
            label="Project Specific Content"
            style={{ width: '80%' }}
          >
            <TextArea />
          </Form.Item>
        </div>
        <div className="flex gap-10">
          <Form.Item
            name={['corsia_first_phase_approved', 'attribute_value']}
            valuePropName='checked'
            style={{ width: '20%' }}
          >
            <Checkbox>{form.getFieldValue(['corsia_first_phase_approved', 'attribute', 'display_name']) || 'Corsia First Phase Approved'}</Checkbox>
          </Form.Item>
          <Form.Item
            name={['corsia_first_phase_approved', 'attribute_extra_content']}
            label="Project Specific Content"
            style={{ width: '80%' }}
          >
            <TextArea />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="On Watch"
        name="onWatch"
        rules={[{ required: false, message: 'Please select an option' }]}
      >
        <Radio.Group>
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Reason (On Watch)"
        name="onWatchReason"
        rules={[{ required: false, message: 'Please input On Watch Reason!' }]}
      >
        <Input.TextArea />
      </Form.Item>
    </>
  );
};

export default ProjectAttributesForm;
