import axios from 'axios';

console.log('build env', process.env.BUILD_ENV);
export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BUILD_ENV === 'prod' ? 'https://platform.calyxglobal.com' : 'https://platform.calyxstaging.com'
    : 'http://localhost:3000';
    
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

let isRefreshing = false;
let failedRequestsQueue: { resolve: Function, reject: Function }[] = [];

// Function to refresh token
async function refreshToken() {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/user/refresh`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '{}')?.response.refresh_token}`,
      },
      validateStatus: null
    });
    const user = {
      response: response.data,
    };
    localStorage.setItem('user', JSON.stringify(user));
    return response.data.access_token;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isRefreshing = false;
  }
}

// Intercept requests and handle token expiration
axiosInstance.interceptors.request.use(async (config) => {
  if (!config.url?.includes('user/refresh') && !config.url?.includes('auth/login')) {
    const token = JSON.parse(localStorage.getItem('user') || '{}')?.response?.access_token;
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Intercept responses and handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config;
    if ((error.response?.status === 401 || error.response?.status === 403) && originalRequest.url !== 'auth/login') {
      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({ resolve, reject });
          }).then(token => {
            return axiosInstance(originalRequest);
          }).catch(err => {
            return Promise.reject(err);
          });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise(async (resolve, reject) => {
          try {
            const accessToken = await refreshToken();
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axiosInstance(originalRequest));
            failedRequestsQueue.forEach((item) => {
              item.resolve(true);
            });
            failedRequestsQueue = [];
          } catch (error) {
            failedRequestsQueue.forEach((item) => {
              item.reject(error);
            });
            failedRequestsQueue = [];
            localStorage.removeItem('user');
            window.location = '/login' as any;
          }
        });
      } else {
        localStorage.removeItem('user');
        window.location = '/login' as any;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;