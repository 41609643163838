import { Button, Form, Input, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';

import useAuth from '../hooks/useAuth';

const Login: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { login, googleLogin } = useAuth();

  const onGoogleLoginSuccess = async (res: CredentialResponse) => {
    googleLogin(res);
  };

  const onGoogleLoginError = () => console.log('Login Failed!');

  const onFinish = async (values: any) => {
    try {
      await login({
        useremail: values.useremail,
        password: values.password,
        platform: 'internal_panel'
      });
    } catch (err: any) {
      let errMsg;
      if (!err?.response) {
        errMsg = 'No Server Response';
      } else if (err.response?.status === 400) {
        errMsg = 'Missing Username or Password';
      } else if (err.response?.status === 401) {
        errMsg = err.response.data?.message || 'Unauthorized';
      } else if (err.response?.status === 403) {
        errMsg = 'Invalid username/password combination';
      } else {
        errMsg = 'Login Failed';
      }
      messageApi.open({
        type: 'error',
        content: errMsg,
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="h-screen flex items-center justify-center">
      {contextHolder}
      <Form
        name="login"
        className="w-96"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="useremail"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input
            autoFocus
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item className="mt-3" wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>

        <Form.Item className="flex justify-end">
          <GoogleLogin onSuccess={onGoogleLoginSuccess} onError={onGoogleLoginError} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
